/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/tphatdev/cdn-tphatdev@edf98d58f087fd66acd07199df29471aacb9cbd7/script.min.js
 * - /gh/tphatdev/cdn-tphatdev@edf98d58f087fd66acd07199df29471aacb9cbd7/dropmenu.min.js
 * - /gh/tphatdev/cdn-tphatdev@edf98d58f087fd66acd07199df29471aacb9cbd7/carousel.min.js
 * - /gh/tphatdev/cdn-tphatdev@edf98d58f087fd66acd07199df29471aacb9cbd7/auth.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
